@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

html, body, #root, .App {
  min-height: 100vh;
}

body {
  margin: 0;
}

.App {
  position: relative;
  min-width: 500px;
}

